// @flow

import { ThemeProvider, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import * as React from 'react'

import * as lib from '@toggl/cookie-warning/src/lib'
import * as gtm from '@toggl/google-tag-manager'
import { colors, media, themes } from '@toggl/style'
import { Button, text, useHasMounted } from '@toggl/ui'

export const CookieWarning = () => {
  const [exists, setExists] = React.useState(false)
  const hasMounted = useHasMounted()

  const setCookie = React.useCallback(() => {
    lib.setCookie()
    gtm.allowConsent()
    setExists(true)
  }, [setExists])

  React.useLayoutEffect(() => {
    if (!hasMounted) {
      return
    }

    if (lib.cookieExists()) {
      setExists(true)
      gtm.allowConsent()
    }
  }, [hasMounted, setExists])

  if (!hasMounted || exists) {
    return null
  }

  return (
    <CookieWarningRoot>
      <P>
        We use cookies to ensure you get the best experience on our site.&nbsp;
        <LinkStyled to='/legal/track/privacy/'>
          <text.Strong>More details</text.Strong>
        </LinkStyled>
        .
      </P>
      <ThemeProvider theme={themes.global}>
        <Button.Primary
          css={css`
            justify-content: center;
            max-width: 7rem;
            height: 40px !important;
          `}
          fluid
          onClick={setCookie}
        >
          Got it
        </Button.Primary>
      </ThemeProvider>
    </CookieWarningRoot>
  )
}

const CookieWarningRoot = styled<{ children: React.Node }>('div')`
  ${text.paragraph4};
  background: ${colors.darkPurple70};
  color: ${colors.white};
  display: flex;
  align-items: center;
  column-gap: 1rem;
  bottom: 0;
  padding: 1rem 1rem 1rem 1.2rem;
  position: fixed;
  right: 0;
  width: 100%;

  /* FIXME: Have to set this high because of weird z-index values, e.g. in the footer and stuff. */
  z-index: 9999999;

  ${media.mq[1]} {
    max-width: 29rem;
    bottom: 1rem;
    right: 1rem;
  }

  @media print {
    display: none;
  }
`

const LinkStyled = styled(Link)`
  color: ${colors.white};
  text-decoration: underline;
`

// TODO: Please replace me with something in `@toggl/ui`.
const P = styled.p`
  color: ${colors.white};
  flex: 1;
  line-height: 1.4;
`
