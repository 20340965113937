import Cookies from 'js-cookie'

const REFERRAL_COOKIE_NAME = 'rewardful.referral'

/**
 * Returns the Rewardful referral ID from the referral cookie.
 *
 * @returns {string | null}
 */
export const getReferral = () => {
  const referral = Cookies.get(REFERRAL_COOKIE_NAME)
  if (!referral) {
    return null
  }
  try {
    const payload = JSON.parse(decodeURIComponent(referral))
    return payload.id
  } catch (e) {
    console.error(e)
    return null
  }
}

/**
 * Checks if shared auth enabled for the current environment by URL.
 *
 * @param location
 * @returns {boolean}
 */
export const isSharedAuthEnabled = (location) => {
  const hostname = location?.hostname ?? window.location.hostname
  return Boolean(
    ['toggl.space', 'toggl.com'].includes(hostname) ||
      hostname.endsWith('ms.toggl.space')
  )
}

/**
 * Returns the URL of the accounts service for the current environment. This
 * works independently of the GATSBY_ACCOUNTS_URL environment variable to
 * satisfy the requirements of custom staging environments.
 *
 * @param locationOverride
 * @returns {string}
 */
export const getAccountsUrl = (locationOverride) => {
  const location =
    locationOverride || (typeof window !== 'undefined' ? window.location : null)
  if (!location) {
    return process.env.GATSBY_ACCOUNTS_URL
  }
  const { hostname } = location
  if (['toggl.space', 'toggl.com'].includes(hostname)) {
    return `https://accounts.${hostname}`
  }
  const stagingEnv = hostname.match(/^((\S)+)\.toggl\.space$/)?.[1]
  if (stagingEnv) {
    return `https://accounts.${stagingEnv}.toggl.space`
  }
  return process.env.GATSBY_ACCOUNTS_URL
}

export const getTrackLoginUrl = (locationOverride) => {
  const location =
    locationOverride || (typeof window !== 'undefined' ? window.location : null)
  if (!location) {
    return '/track/login/'
  }
  const sharedAuthEnabled = isSharedAuthEnabled(location)
  if (!sharedAuthEnabled) {
    return '/track/login/'
  }
  return new URL('/track/login/', getAccountsUrl(location)).toString()
}

export const getTrackSignupUrl = (locationOverride) => {
  const location =
    locationOverride || (typeof window !== 'undefined' ? window.location : null)
  if (!location) {
    return '/track/signup/'
  }
  const sharedAuthEnabled = isSharedAuthEnabled(location)
  if (!sharedAuthEnabled) {
    return '/track/signup/'
  }
  return new URL('/track/signup/', getAccountsUrl(location)).toString()
}
