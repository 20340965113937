// @flow
const initialize = () => {
  // avo-inspector is browser-only
  if (
    typeof window === 'undefined' ||
    typeof process.env.JEST_WORKER_ID !== 'undefined'
  ) {
    return null
  }
  const Inspector = require('avo-inspector')
  return new Inspector.AvoInspector({
    apiKey: process.env.GATSBY_AVO_INSPECTOR_API_KEY,
    env: getAvoEnv(),
    version: process.env.GATSBY_VERSION ?? '',
    appName: 'Public Web',
  })
}

export const avoInspector = initialize()

function getAvoEnv() {
  if (process.env.PRODUCTION) {
    return 'prod'
  } else if (process.env.STAGING) {
    return 'staging'
  } else {
    return 'dev'
  }
}
