// @flow
export const darkPurple100 = '#2C1338'
export const darkPurple90 = '#412A4C'
export const darkPurple80 = '#564260'
export const darkPurple70 = '#6B5A74'
export const darkPurple60 = '#817187'
export const darkPurple50 = '#95899B'
export const darkPurple40 = '#ABA0AF'
export const darkPurple30 = '#C0B8C3'
export const darkPurple20 = '#D5D0D7'
export const darkPurple10 = '#EAE7EB'

export const yellow180 = '#AE7000'
export const yellow120 = '#FFCD59'
export const yellow100 = '#FFDE91'
export const yellow80 = '#FFE4A7'
export const yellow60 = '#FFEBBD'
export const yellow40 = '#FFF2D3'
export const yellow20 = '#FFF8E9'

export const peach110 = '#FAD6C6'
export const peach100 = '#FCE5D8'
export const peach80 = '#FDEAE2'
export const peach50 = '#FDF1EC'
export const peach30 = '#FEF6F3'
export const peach20 = '#FEF9F7'
export const peach10 = '#FEFBFA'

export const pink110 = '#DD6FD1'
export const pink100 = '#E57CD8'
export const pink90 = '#E889DC'
export const pink80 = '#EA96E0'
export const pink70 = '#EDA3E4'
export const pink50 = '#F2BDEC'
export const pink30 = '#F7D8F3'
export const pink20 = '#FAE5F7'
export const pink10 = '#FCF2FB'

export const violet = '#A876F5'
export const violet60 = '#CBADF9'
export const violet40 = '#DCC8FB'
export const violet20 = '#EEE4FD'

export const orange = '#FF8A7A'
export const orange90 = '#FF9587'
export const orange80 = '#FFA195'
export const orange60 = '#FFB8AF'
export const orange30 = '#FFDCD7'
export const orange20 = '#FFE7E4'
export const orange10 = '#FFF3F2'

export const teal150 = '#2A8367'
export const teal110 = '#179D73'
export const teal100 = '#59C9A5'

export const white = '#FFFFFF'
export const red = '#FF4940'
export const green = '#21A666'
