// @flow

import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import * as gtm from '@toggl/google-tag-manager'
import { colors } from '@toggl/style'

import { CookieWarning } from '../CookieWarning'

declare var process: WebProcess

export const LayoutRoot = ({
  title,
  description,
  children,
  ogImage,
  ogImageAlt,
  noSmoothScrolling = false,
}: {
  title?: string,
  description?: string,
  children: React.Node,
  ogImage?: string,
  ogImageAlt?: string,
  noSmoothScrolling?: boolean,
}): React.Element<typeof StyledLayoutRoot> => {
  React.useLayoutEffect(() => {
    gtm.activateOptimize()
    gtm.activateProfitWell()
  }, [])

  return (
    <StyledLayoutRoot>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title itemProp='name'>{title}</title>
        <meta property='og:title' content={title} />
        <meta
          name='description'
          property='og:description'
          content={
            description ||
            'When time tracking is this easy, you can get more done. Toggl Track data brings clarity around profitability and helps you make better business decisions.'
          }
        />
        <meta
          property='og:image'
          content={ogImage || require('assets/toggl-track-main.jpg').default}
        />
        {ogImageAlt && <meta property='og:image:alt' content={ogImageAlt} />}
        <meta name='twitter:card' content='summary_large_image' />
        <link
          rel='preconnect'
          href={new URL(process.env.GATSBY_PUBLIC_PATH).origin}
        />
        <link
          rel='preconnect'
          href={new URL(process.env.GATSBY_PUBLIC_PATH).origin}
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href={
            require('@toggl/fonts/gt-haptik-medium-500.latin.woff2').default
          }
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href={
            require('@toggl/fonts/gt-haptik-medium-rotalic-500.latin.woff2')
              .default
          }
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href={require('@toggl/fonts/inter-400.latin.woff2').default}
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href={require('@toggl/fonts/inter-500.latin.woff2').default}
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link rel='dns-prefetch' href='https://www.googletagmanager.com' />
        <link rel='dns-prefetch' href='https://www.googleadservices.com' />
        <link rel='dns-prefetch' href='https://googleads.g.doubleclick.net' />
        <link rel='dns-prefetch' href='https://stats.g.doubleclick.net' />
        <link rel='dns-prefetch' href='https://bat.bing.com' />
        <link rel='dns-prefetch' href='https://facebook.com' />
        <link rel='dns-prefetch' href='https://connect.facebook.net' />
        <link rel='dns-prefetch' href='https://px.ads.linkedin.com' />
        <link rel='dns-prefetch' href='https://snap.licdn.com' />

        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={require('./assets/favicon/apple-touch-icon.png').default}
        />
        <link
          rel='icon'
          href={require('./assets/favicon/favicon.svg').default}
          type='image/svg+xml'
        ></link>
        <link
          rel='icon'
          href={require('./assets/favicon/favicon.ico').default}
          sizes='any'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='48x48'
          href={require('./assets/favicon/favicon-48x48.png').default}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={require('./assets/favicon/favicon-32x32.png').default}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={require('./assets/favicon/favicon-16x16.png').default}
        />

        <script>
          {`
            window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
            heap.load("${process.env.GATSBY_HEAP_ID}");
          `}
        </script>
      </Helmet>

      {noSmoothScrolling && (
        <Global
          styles={css`
            html {
              scroll-behavior: auto;
            }
          `}
        />
      )}
      {children}
      {process.env.GATSBY_NEW_COOKIE_CONSENT_ENABLED !== 'true' && (
        <CookieWarning />
      )}
    </StyledLayoutRoot>
  )
}

const StyledLayoutRoot = styled.div<{ ... }>(`
  background-color: ${colors.darkPurple100};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`)
